// external js: isotope.pkgd.js

// store filter for each group
var buttonFilters = {};
var buttonFilter;
// quick search regex
var qsRegex;
// url param
var gemeinde;

// URL-Parameter laden
function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
  sURLVariables = sPageURL.split('&'),
  sParameterName,
  i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

// var filterFns = {
//   regExFilter: function() {
//     return qsRegex ? $(this).text().match( qsRegex ) : true;
//   },
//   maxItemFilter: function() {
//     return $(this).index() < 4;
//   }
// };

// init Isotope
var $grid = $('.nav--pdfs').isotope({
  itemSelector: '.nav-item',
  filter: function() {
    var $this = $(this);
    var searchResult = qsRegex ? $this.text().match( qsRegex ) : true;
    var buttonResult = buttonFilter ? $this.is( buttonFilter ) : true;
    return searchResult && buttonResult;
  },
});

// use value of search field to filter
var $quicksearch = $('.quicksearch').keyup( debounce( function() {
  qsRegex = new RegExp( $quicksearch.val(), 'gi' );
  $grid.isotope();
}) );

gemeinde = getUrlParameter('gemeinde');
if(gemeinde && $('.quicksearch').length){
    // search for keyword 
    $('.quicksearch').val(gemeinde);
    $grid.isotope('reloadItems').isotope();
		$('.quicksearch').keyup()
}

// $('.filters').on( 'click', '.button', function() {
//   var $this = $(this);
//   // get group key
//   var $buttonGroup = $this.parents('.button-group');
//   var filterGroup = $buttonGroup.attr('data-filter-group');
//   // set filter for group
//   buttonFilters[ filterGroup ] = $this.attr('data-filter');
//   // combine filters
//   buttonFilter = concatValues( buttonFilters );
//   // Isotope arrange
//   $grid.isotope();
// });


// Verhindern, dass «Submit» ausgelöst wird, wenn man Enter drückt.
$('.quicksearch').bind('keypress', function(e)
{
  if(e.keyCode == 13)
  {
    return false;
  }
});

// // change is-checked class on buttons
// $('.button-group').each( function( i, buttonGroup ) {
//   var $buttonGroup = $( buttonGroup );
//   $buttonGroup.on( 'click', 'button', function() {
//     $buttonGroup.find('.is-checked').removeClass('is-checked');
//     $( this ).addClass('is-checked');
//   });
// });
  
// flatten object by concatting values
function concatValues( obj ) {
  var value = '';
  for ( var prop in obj ) {
    value += obj[ prop ];
  }
  return value;
}

// debounce so filtering doesn't happen every millisecond
function debounce( fn, threshold ) {
  var timeout;
  threshold = threshold || 100;
  return function debounced() {
    clearTimeout( timeout );
    var args = arguments;
    var _this = this;
    function delayed() {
      fn.apply( _this, args );
    }
    timeout = setTimeout( delayed, threshold );
  };
}

